'use client';

import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Sheet, SheetContent, SheetDescription, SheetTitle, SheetTrigger } from '@/components/ui/sheet';
import { cn } from '@/lib/utils';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import { ReactNode } from 'react';
type Props = {
  trigger: ReactNode;
  heading?: string;
  closeCTA?: ReactNode;
  children: ReactNode;
  defaultOpen?: boolean;
  contentClassnames?: string;
  onCleanup?: () => void;
};
const ResponsivePopover = ({
  trigger,
  defaultOpen = false,
  onCleanup,
  contentClassnames,
  children
}: Props) => {
  const handleCleanup = (open: boolean) => {
    if (!open) {
      onCleanup?.();
    }
  };
  return <>
      <div className="hidden md:block">
        <Popover defaultOpen={defaultOpen} onOpenChange={handleCleanup} data-sentry-element="Popover" data-sentry-source-file="ResponsivePopover.tsx" data-sentry-element="Popover">
          <PopoverTrigger data-sentry-element="PopoverTrigger" data-sentry-source-file="ResponsivePopover.tsx" data-sentry-element="PopoverTrigger">{trigger}</PopoverTrigger>

          <PopoverContent className={cn('mt-2 sm:max-w-[425px] ', contentClassnames)} align="end" data-sentry-element="PopoverContent" data-sentry-source-file="ResponsivePopover.tsx" data-sentry-element="PopoverContent">
            <div className="flex flex-col">{children}</div>
          </PopoverContent>
        </Popover>
      </div>
      <div className="block md:hidden">
        <Sheet defaultOpen={defaultOpen} onOpenChange={handleCleanup} data-sentry-element="Sheet" data-sentry-source-file="ResponsivePopover.tsx" data-sentry-element="Sheet">
          <SheetDescription className="hidden" data-sentry-element="SheetDescription" data-sentry-source-file="ResponsivePopover.tsx" data-sentry-element="SheetDescription"></SheetDescription>
          <SheetTrigger data-sentry-element="SheetTrigger" data-sentry-source-file="ResponsivePopover.tsx" data-sentry-element="SheetTrigger">{trigger}</SheetTrigger>
          <SheetContent side="bottom" className={cn('rounded-t-lg', contentClassnames)} data-sentry-element="SheetContent" data-sentry-source-file="ResponsivePopover.tsx" data-sentry-element="SheetContent">
            <div className="flex flex-col mt-6">{children}</div>
            <VisuallyHidden.Root data-sentry-element="unknown" data-sentry-source-file="ResponsivePopover.tsx" data-sentry-element="unknown">
              <SheetTitle data-sentry-element="SheetTitle" data-sentry-source-file="ResponsivePopover.tsx" data-sentry-element="SheetTitle">title</SheetTitle>
            </VisuallyHidden.Root>
          </SheetContent>
        </Sheet>
      </div>
    </>;
};
export default ResponsivePopover;