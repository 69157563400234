import React from 'react';
import ResponsivePopover from '../ResponsivePopover';
import { IoSettingsSharp } from 'react-icons/io5';
import { ToastPosition, useUIStore } from '@/store/ui';
import { cn } from '@/lib/utils';
import { Separator } from '@radix-ui/react-dropdown-menu';
import { Switch } from '@/components/ui/switch';
import { Button } from '@/components/ui/button';
export const UserPreferencesContent = () => {
  const {
    skipOpenTradeConfirmations,
    skipCloseTradeConfirmations,
    showTradeRightPanel,
    updateOpenTradeConfirmations,
    updateCloseTradeConfirmations,
    updateShowTradeRightPanel,
    showRecentTradesAndNews,
    showAnnouncements,
    updateShowRecentTradesAndNews,
    toastPosition,
    updateToastPosition,
    updateAnnouncements,
    reset
  } = useUIStore();
  return <>
      <div>
        <div className="space-y-4">
          <p className="text-grey-050 leading-[100%]">Trade Panel Position</p>
          <div className="flex space-x-2">
            <div className={cn('h-[47px] w-[98px] p-[7px_9px_8px_9px] rounded-md border border-[#181a20] bg-[#181a20]')}>
              <div role="button" onClick={() => updateShowTradeRightPanel(false)} className={cn("relative h-8 w-20 border border-[#a8acb8] outline outline-2 outline-[#181a20] -outline-offset-[3.25px] rounded-sm bg-[#a8acb8] after:absolute after:content-[''] after:bg-[#a8acb8]", 'after:absolute after:top-0.5 after:bottom-0.5 after:right-[55px] after:border-r-2 after:border-[#181a20]', !showTradeRightPanel ? 'border-[#96e21b] bg-[#96e21b] after:bg-[#96e21b]' : '')} />
            </div>
            <div className={cn('h-[47px] w-[98px] p-[7px_9px_8px_9px] rounded-md border border-[#181a20] bg-[#181a20]')}>
              <div role="button" onClick={() => updateShowTradeRightPanel(true)} className={cn("relative h-8 w-20 border border-[#a8acb8] outline outline-2 outline-[#181a20] -outline-offset-[3.25px] rounded-sm bg-[#a8acb8] after:absolute after:content-[''] after:bg-[#a8acb8]", 'after:absolute after:top-0.5 after:bottom-0.5 after:left-[55px] after:border-r-2 after:border-[#181a20]', showTradeRightPanel ? 'border-[#96e21b] bg-[#96e21b] after:bg-[#96e21b]' : '')} />
            </div>
          </div>
        </div>
        <Separator className="border-b my-4" data-sentry-element="Separator" data-sentry-source-file="UserPreferences.tsx" data-sentry-element="Separator" />
        <div className="space-y-4">
          <p className="text-grey-050 leading-[100%]">Notification and Alerts Position</p>
          <div className="flex space-x-2">
            <div className={cn('h-[47px] w-[98px] p-[7px_9px_8px_9px] rounded-md border border-[#181a20] bg-[#181a20]')}>
              <div role="button" onClick={() => updateToastPosition(ToastPosition.TOP_LEFT)} className={cn("relative h-8 w-20 border border-[#a8acb8] rounded-sm after:absolute after:content-[''] after:bg-[#a8acb8]", 'after:absolute after:top-1 after:left-1 after:bottom-[60%] after:right-[60%] after:rounded-[1px]', toastPosition === ToastPosition.TOP_LEFT ? 'border-[#96e21b] after:bg-[#96e21b]' : '')} />
            </div>
            <div className={cn('h-[47px] w-[98px] p-[7px_9px_8px_9px] rounded-md border border-[#181a20] bg-[#181a20]')}>
              <div role="button" onClick={() => updateToastPosition(ToastPosition.TOP_RIGHT)} className={cn("relative h-8 w-20 border border-[#a8acb8] rounded-sm after:absolute after:content-[''] after:bg-[#a8acb8]", 'after:absolute after:top-1 after:left-[60%] after:bottom-[60%] after:right-1 after:rounded-[1px]', toastPosition === ToastPosition.TOP_RIGHT ? 'border-[#96e21b] after:bg-[#96e21b]' : '')} />
            </div>
            <div className={cn('h-[47px] w-[98px] p-[7px_9px_8px_9px] rounded-md border border-[#181a20] bg-[#181a20]')}>
              <div role="button" onClick={() => updateToastPosition(ToastPosition.BOTTOM_LEFT)} className={cn("relative h-8 w-20 border border-[#a8acb8] rounded-sm after:absolute after:content-[''] after:bg-[#a8acb8]", 'after:absolute after:top-[60%] after:left-1 after:bottom-1 after:right-[60%] after:rounded-[1px]', toastPosition === ToastPosition.BOTTOM_LEFT ? 'border-[#96e21b] after:bg-[#96e21b]' : '')} />
            </div>
            <div className={cn('h-[47px] w-[98px] p-[7px_9px_8px_9px] rounded-md border border-[#181a20] bg-[#181a20]')}>
              <div role="button" onClick={() => updateToastPosition(ToastPosition.BOTTOM_RIGHT)} className={cn("relative h-8 w-20 border border-[#a8acb8] rounded-sm after:absolute after:content-[''] after:bg-[#a8acb8]", 'after:absolute after:top-[60%] after:left-[60%] after:bottom-1 after:right-1 after:rounded-[1px]', toastPosition === ToastPosition.BOTTOM_RIGHT ? 'border-[#96e21b] after:bg-[#96e21b]' : '')} />
            </div>
          </div>
        </div>
        <Separator className="border-b my-4" data-sentry-element="Separator" data-sentry-source-file="UserPreferences.tsx" data-sentry-element="Separator" />
        <div className="space-y-4">
          <p className="text-grey-050 leading-[100%]">Order book and News</p>
          <div className="flex items-center justify-between mt-1">
            <p className="text-sm text-grey-100">Show recent trades and market News</p>
            <Switch className="data-[state=checked]:bg-primary-200" checked={showRecentTradesAndNews} onClick={() => {
            updateShowRecentTradesAndNews(!showRecentTradesAndNews);
          }} data-sentry-element="Switch" data-sentry-source-file="UserPreferences.tsx" data-sentry-element="Switch" />
          </div>
        </div>
        <Separator className="border-b my-4" data-sentry-element="Separator" data-sentry-source-file="UserPreferences.tsx" data-sentry-element="Separator" />
        <div className="space-y-4">
          <p className="text-grey-050 leading-[100%]">Announcements</p>
          <div className="flex items-center justify-between mt-1">
            <p className="text-sm text-grey-100">Show announcements</p>
            <Switch className="data-[state=checked]:bg-primary-200" checked={showAnnouncements} onClick={() => {
            updateAnnouncements(!showAnnouncements);
          }} data-sentry-element="Switch" data-sentry-source-file="UserPreferences.tsx" data-sentry-element="Switch" />
          </div>
        </div>
        <Separator className="border-b my-4" data-sentry-element="Separator" data-sentry-source-file="UserPreferences.tsx" data-sentry-element="Separator" />
        <div className="space-y-4">
          <p className="text-grey-050 leading-[100%]">Trade Confirmations</p>
          <div className="flex items-center justify-between">
            <p className="text-sm text-grey-100">Skip open order confirmation</p>
            <Switch className="data-[state=checked]:bg-primary-200" checked={skipOpenTradeConfirmations} onClick={() => {
            updateOpenTradeConfirmations(!skipOpenTradeConfirmations);
          }} data-sentry-element="Switch" data-sentry-source-file="UserPreferences.tsx" data-sentry-element="Switch" />
          </div>
          <div className="flex items-center justify-between">
            <p className="text-sm text-grey-100">Skip close order confirmation</p>
            <Switch className="data-[state=checked]:bg-primary-200" checked={skipCloseTradeConfirmations} onClick={() => {
            updateCloseTradeConfirmations(!skipCloseTradeConfirmations);
          }} data-sentry-element="Switch" data-sentry-source-file="UserPreferences.tsx" data-sentry-element="Switch" />
          </div>
        </div>
      </div>
      <Separator className="border-b my-4" data-sentry-element="Separator" data-sentry-source-file="UserPreferences.tsx" data-sentry-element="Separator" />
      <Button variant="actions" className="h-12 text-base text-grey-000 hover:bg-grey-400" onClick={reset} data-sentry-element="Button" data-sentry-source-file="UserPreferences.tsx" data-sentry-element="Button">
        Reset Preferences
      </Button>
    </>;
};
const UserPreferences = () => {
  return <ResponsivePopover trigger={<div role="button" className="p-3 rounded bg-grey-600 h-11 flex gap-2 items-center">
          <IoSettingsSharp className="size-5" />
        </div>} contentClassnames="w-full md:min-w-[416px]" data-sentry-element="ResponsivePopover" data-sentry-component="UserPreferences" data-sentry-source-file="UserPreferences.tsx">
      <UserPreferencesContent data-sentry-element="UserPreferencesContent" data-sentry-source-file="UserPreferences.tsx" data-sentry-element="UserPreferencesContent" />
    </ResponsivePopover>;
};
export default UserPreferences;