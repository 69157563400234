'use client';

import { WagmiProvider } from 'wagmi';
import { config } from '@/blockchain/configs/wagmi';
import { DisclaimerComponent, RainbowKitProvider, Theme, darkTheme } from '@rainbow-me/rainbowkit';
import { isServer, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import merge from 'lodash.merge';
import { Toaster } from '@/components/ui/toaster';
import TNCCheck from '@/components/shared/TNCCheck';
// import BetaCheck from '@/components/shared/BetaCheck';
import { ErrorBoundary } from '@sentry/nextjs';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import Header from '@/components/shared/header/Header';
import Announcement from '@/components/shared/Announcement';
import { useState } from 'react';
import Banner from '@/components/shared/Banner';
const avantisTheme = merge(darkTheme(), {
  colors: {
    accentColor: '#7d17ff',
    accentColorForeground: '#000'
  },
  radii: {
    modal: '8px',
    modalMobile: '8px'
  }
} as Theme);
function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        // With SSR, we usually want to set some default staleTime
        // above 0 to avoid refetching immediately on the client
        staleTime: 60 * 1000
      }
    }
  });
}
let browserQueryClient: QueryClient | undefined = undefined;
function getQueryClient() {
  if (isServer) {
    // Server: always make a new query client
    return makeQueryClient();
  } else {
    // Browser: make a new query client if we don't already have one
    // This is very important, so we don't re-make a new client if React
    // suspends during the initial render. This may not be needed if we
    // have a suspense boundary BELOW the creation of the query client
    if (!browserQueryClient) browserQueryClient = makeQueryClient();
    return browserQueryClient;
  }
}

// eslint-disable-next-line react/prop-types
const Disclaimer: DisclaimerComponent = ({
  Text
}) => <Text data-sentry-element="Text" data-sentry-component="Disclaimer" data-sentry-source-file="AppProvider.tsx">
    <p className="text-sm">To continue, please connect your wallet</p>
  </Text>;
export function AppProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const [queryClient] = useState(getQueryClient());
  const pathname = usePathname();
  const isLanding = pathname === '/' || pathname === '/blocked';
  return <WagmiProvider config={config} data-sentry-element="WagmiProvider" data-sentry-component="AppProvider" data-sentry-source-file="AppProvider.tsx">
      <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="AppProvider.tsx" data-sentry-element="QueryClientProvider">
        <RainbowKitProvider appInfo={{
        disclaimer: Disclaimer
      }} theme={avantisTheme} modalSize="compact" data-sentry-element="RainbowKitProvider" data-sentry-source-file="AppProvider.tsx" data-sentry-element="RainbowKitProvider">
          <TNCCheck data-sentry-element="TNCCheck" data-sentry-source-file="AppProvider.tsx" data-sentry-element="TNCCheck" />
          {/* <BetaCheck /> */}
          <ErrorBoundary fallback={data => <div className="h-[500px] w-full p-8 flex flex-col items-center justify-center text-center">
                <Image className="hidden md:block" src="/images/avantis-logo.svg" alt="Avantis" width={118} height={22} />
                <Image className="md:hidden" src="/images/avantis-logo-white.svg" alt="Avantis" width={37} height={23} />
                <p className="text-2xl mt-6">Uh oh! Something went wrong</p>
                <p className="mt-3 text-error-100"> Error: {(data.error as any)?.message}</p>
                <p className="mt-3">Our Team has been notified. Please try again later</p>
                <div className="flex items-center gap-4 mt-6">
                  <Link className="inline-flex items-center gap-1 px-3 py-2 rounded-md gradient-purple text-primary-foreground hover:bg-primary/90 text-sm" href="https://discord.gg/qJabbFzcUn" target="_blank" rel="noopener noreferrer">
                    Contact Support
                  </Link>
                  <div role="button" className="text-sm px-3 py-2 rounded-md bg-primary-200 text-grey-800" onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}>
                    Clear Browser Cache
                  </div>
                </div>
              </div>} data-sentry-element="ErrorBoundary" data-sentry-source-file="AppProvider.tsx" data-sentry-element="ErrorBoundary">
            {!isLanding && <>
                <Banner />
                <Header />
                <Announcement />
              </>}
            {children}
          </ErrorBoundary>
          <Toaster data-sentry-element="Toaster" data-sentry-source-file="AppProvider.tsx" data-sentry-element="Toaster" />
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>;
}
export default AppProvider;