'use client';

import useAppStatus from '@/hooks/shared/useAppStatus';
import { usePathname } from 'next/navigation';
const Banner = () => {
  const pathname = usePathname();
  const {
    data: status
  } = useAppStatus();
  if (!status?.isActive) {
    return null;
  }
  if (pathname !== '/trade' && !status?.isGlobal) {
    return null;
  }
  return <div className="p-2 md:p-2 text-center font-medium text-sm md:text-base" style={{
    backgroundColor: status?.backgroundColor ?? '#BDF556',
    color: status?.color ?? '#131F19'
  }} data-sentry-component="Banner" data-sentry-source-file="Banner.tsx">
      {status.message}
    </div>;
};
export default Banner;