export const enum AnnouncementType {
  MARQUEE = 'MARQUEE',
  STILL = 'STILL',
}

export const announcements = {
  type: AnnouncementType.MARQUEE,
  label: 'NEW ASSETS',
  value: [
    {
      id: 1,
      assetUri: '/images/pairs/crypto/EIGEN.svg',
      base: 'EIGEN',
      quote: 'USD',
      leverage: 10,
      desc: 'Trade EIGEN-USD with up to 10x Leverage',
    },
    {
      id: 2,
      assetUri: '/images/pairs/crypto/INJ.svg',
      base: 'INJ',
      quote: 'USD',
      leverage: 40,
      desc: 'Trade INJ-USD with up to 40x Leverage',
    },
    {
      id: 3,
      base: 'ZK',
      quote: 'USD',
      leverage: 15,
      assetUri: '/images/pairs/crypto/ZK.svg',
      desc: 'Trade ZK-USD with up to 15x Leverage',
    },
    {
      id: 4,
      assetUri: '/images/pairs/crypto/SUI.svg',
      base: 'SUI',
      quote: 'USD',
      leverage: 40,
      desc: 'Trade SUI-USD with up to 40x Leverage',
    },
    {
      id: 5,
      assetUri: '/images/pairs/crypto/TAO.svg',
      base: 'TAO',
      quote: 'USD',
      leverage: 10,
      desc: 'Trade TAO-USD with up to 10x Leverage',
    },
    {
      id: 6,
      assetUri: '/images/pairs/crypto/AAVE.svg',
      base: 'AAVE',
      quote: 'USD',
      leverage: 40,
      desc: 'Trade NEAR-USD with up to 15x Leverage',
    },
    {
      id: 7,
      assetUri: '/images/pairs/crypto/ZRO.svg',
      base: 'ZRO',
      quote: 'USD',
      leverage: 15,
      desc: 'Trade ZRO-USD with up to 15x Leverage',
    },
  ],
};

// export const announcements = {
//   announcementType: 'AVANTIS_XP_IS_LIVE',
//   value:
//     'Trade, refer, and provide liquidity to earn Avantis XP. Complete challenges to earn XP boosts and climb the leaderboard.',
// }
