import { useAccount } from 'wagmi';

import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { requestApp } from '@/lib/api';
import { formatNumber, formatNumberWithDecimal } from '@/lib/utils';
import { calculateUserPercentile } from '@/lib/xp';
import usePortfolioStats from '../portfolio/usePortfolioStats';

export type BadgeData = {
  activateTs: number;
  _id: string;
  badge: string;
  userAddress: string;
  __v: number;
  badgeInfo: {
    lastTradeDate?: string;
    streakLength?: number;
    message?: string;
  };
  badgeLevel: number;
  badgeType: string;
  boostValue: number;
  createdAt: string;
  status: string;
  updatedAt: string;
};

export type PointsResponse = {
  success: boolean;
  pointsData: {
    _id: string;
    userAddress: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
    totalPoints: number;
    volumeBoostPoints: number;
    volumePoints: number;
    cumulativeTradingVolume: number;
    referralBoostPoints: number;
    referralPoints: number;
    cumulativeLiquidity: number;
    juniorLpPoints: number;
    liquidityBoostPoints: number;
    liquidityPoints: number;
    liquidityPointsUpdatedAt: string;
    seniorLpPoints: number;
    pendingLockedPoints: number;
    lockedPoints: number;
    seniorTrancheLocked: number;
    juniorTrancheLocked: number;
  };
  badgesData: Array<BadgeData>;
  boostData: {
    liquidityBoost: number;
    volumeBoost: number;
    referralBoost: number;
  };
  rankData: {
    userVolumeTradingRank: number;
    userLiquidityRank: number;
    userReferralRank: number;
    totalVolumeTraders: number;
    totalLiquidityProviders: number;
    totalReferralProviders: number;
  };
  lpPointsData: {
    cumulativeLiquidity: number;
    juniorTrancheLocked: number;
    seniorTrancheLocked: number;
    dailyPoints: number;
  };
};

const useUserPoints = () => {
  const { address } = useAccount();

  const { data: userPointsData, isFetching: isLoadingPoints } = useQuery({
    queryKey: ['points-info', address],
    queryFn: async () => {
      const response = await requestApp<PointsResponse>({
        endpoint: `/points/info/${address}`,
      });
      return response;
    },

    enabled: !!address,
  });

  const {
    totalVolumeData: { data: tVolumeData, isLoading: totalVolumeIsLoading },
  } = usePortfolioStats();

  const userPoints = useMemo(
    () =>
      userPointsData?.success
        ? {
            trading: {
              pointsRaw:
                (userPointsData?.pointsData.volumePoints || 0) +
                (userPointsData?.pointsData.referralPoints || 0),
              points: formatNumberWithDecimal(
                (userPointsData?.pointsData.volumePoints || 0) +
                  (userPointsData?.pointsData.referralPoints || 0)
              ),
              volume: {
                points: userPointsData?.pointsData.volumePoints,
                boostPoints: `${formatNumber(Math.max(userPointsData?.boostData?.volumeBoost || 0, 1))}x`,
              },
              referral: {
                points: userPointsData?.pointsData.referralPoints,
                boostPoints: `${formatNumber(Math.max(userPointsData?.boostData?.referralBoost || 0, 1))}x`,
              },
              boostPoints: userPointsData?.pointsData.volumeBoostPoints,
              cumulativeVolume: formatNumber(userPointsData?.pointsData.cumulativeTradingVolume),
              boost: `${formatNumber(
                Math.max(
                  (userPointsData?.boostData?.volumeBoost || 0) +
                    (userPointsData?.boostData?.referralBoost || 0),
                  1
                )
              )}x`,
              rank:
                Math.min(
                  userPointsData?.rankData.userVolumeTradingRank,
                  userPointsData?.rankData.totalVolumeTraders
                ) || '-',
              totalUsers: userPointsData?.rankData.totalVolumeTraders || '-',
              percentile: calculateUserPercentile(
                Math.min(
                  userPointsData?.rankData.userVolumeTradingRank,
                  userPointsData?.rankData.totalVolumeTraders
                ),
                Number(userPointsData?.rankData.totalVolumeTraders)
              ),
            },
            liquidity: {
              pointsRaw: userPointsData?.pointsData.liquidityPoints,
              points: formatNumberWithDecimal(userPointsData?.pointsData.liquidityPoints),
              boostPoints: userPointsData?.pointsData.liquidityBoostPoints,
              cumulativeVolume: formatNumber(userPointsData?.pointsData.cumulativeLiquidity),
              boost: `${formatNumber(Math.max(userPointsData?.boostData?.liquidityBoost || 0, 1))}x`,
              rank:
                Math.min(
                  userPointsData?.rankData.userLiquidityRank,
                  userPointsData?.rankData.totalLiquidityProviders
                ) || '-',
              juniorTrancheLocked: formatNumber(
                Math.abs(userPointsData?.pointsData?.juniorLpPoints || 0)
              ),
              seniorTrancheLocked: formatNumber(
                Math.abs(userPointsData?.pointsData?.seniorLpPoints || 0)
              ),
              lockedPoints: formatNumber(Math.abs(userPointsData?.pointsData?.lockedPoints || 0)),
              pendingLockedPoints: formatNumber(
                Math.abs(userPointsData?.pointsData?.pendingLockedPoints || 0)
              ),
              totalUsers: userPointsData?.rankData.totalLiquidityProviders || 0,
              dailyPoints: userPointsData?.lpPointsData?.dailyPoints
                ? formatNumber(userPointsData?.lpPointsData?.dailyPoints)
                : 0,
              percentile: calculateUserPercentile(
                Math.min(
                  userPointsData?.rankData.userLiquidityRank,
                  userPointsData?.rankData.totalLiquidityProviders
                ),
                Number(userPointsData?.rankData.totalLiquidityProviders)
              ),
            },
            lpPointsData: {
              dailyPoints: userPointsData?.lpPointsData?.dailyPoints
                ? formatNumber(userPointsData?.lpPointsData?.dailyPoints)
                : 0,
              juniorTrancheLocked: userPointsData?.pointsData?.juniorLpPoints || 0,
              seniorTrancheLocked: userPointsData?.pointsData?.seniorLpPoints || 0,
              pendingLockedPoints: userPointsData?.pointsData?.pendingLockedPoints || 0,
              lockedPoints: userPointsData?.pointsData?.lockedPoints || 0,
            },
            rankData: userPointsData?.rankData,
            totalPoints: userPointsData?.pointsData.totalPoints,
            totalVolume: tVolumeData?.data?.[0]?.total ? tVolumeData?.data?.[0]?.total : '',
            badges: [...userPointsData?.badgesData],
          }
        : null,
    [userPointsData, tVolumeData]
  );

  return { data: userPoints, isLoading: isLoadingPoints || totalVolumeIsLoading };
};

export default useUserPoints;
