import { create } from 'zustand';

type State = {
  isAuthorized: boolean;
  openOnboard1CT: boolean;
  walletOI: number;
};

type Action = {
  updateUserStore: (key: keyof State, value: any) => void;
  resetStore: () => void;
};

const initialState = {
  isAuthorized: false,
  openOnboard1CT: false,
  walletOI: 0,
};

export const useUserStore = create<State & Action>((set) => ({
  ...initialState,
  updateUserStore: (key, value) => set(() => ({ [key]: value })),
  resetStore: () => set(() => initialState),
}));
