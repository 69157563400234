import { Button } from '@/components/ui/button';
import ResponsiveDialog from '../../ResponsiveDialog';
import ButtonCTA from './ButtonCTA';
import BalanceSection from './BalanceSection';
import InputWithBalance from '../../InputWithBalance';
import InputRightSide from './InputRightSide';
import TradesCount from './TradesCount';
import { sanitizeInputs } from '@/lib/numbers';
import BigNumber from 'bignumber.js';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import useWalletBalances from '@/hooks/shared/useWalletBalance';
import useWalletTransaction from '@/hooks/trade/useWalletTransactions';
import { useOneCTStore } from '@/store/oneCT';
import { useAccount } from 'wagmi';
import useOneCT from '@/hooks/shared/useOneCT';
import { IAsset } from './ManageFunds';
import { Address } from 'viem';
import { noOp } from '@/constants/common';
import useExecutionFee from '@/hooks/trade/useExecutionFee';
import { getPrecision } from '@/lib/utils';
const ManageFundsModal = ({
  isAdd,
  asset,
  openManageFunds,
  setOpenManageFunds,
  onComplete = noOp,
  onCleanup = noOp
}: {
  isAdd: boolean;
  asset: IAsset;
  openManageFunds: boolean;
  setOpenManageFunds: Dispatch<SetStateAction<boolean>>;
  onComplete?: () => void;
  onCleanup?: () => void;
}) => {
  const [input, setInput] = useState<string>('0');
  const {
    currentWalletBalance,
    walletBalanceByAddress
  } = useWalletBalances();
  const {
    depositETH,
    depositUSDC,
    withdrawETH,
    withdrawUSDC
  } = useWalletTransaction();
  const {
    publicKey
  } = useOneCTStore(state => state);
  const {
    address
  } = useAccount();
  const [err, setErr] = useState<string | null>(null);
  const [tradeCount, setTradeCount] = useState<number>(15);
  const {
    gasPerTradeEst
  } = useOneCT();
  const [tradeEst, setTradeEst] = useState(0);
  const executionFee = useExecutionFee();
  const getBalance = () => {
    if (isAdd && asset === 'ETH') {
      return walletBalanceByAddress[address as Address]?.eth.formatted.toString() ?? '0';
    } else if (isAdd && asset === 'USDC') {
      return walletBalanceByAddress[address as Address]?.usdc.formatted.toString() ?? '0';
    } else if (!isAdd && asset === 'ETH') {
      return walletBalanceByAddress[publicKey as Address]?.eth.formatted.toString() ?? '0';
    }
    return walletBalanceByAddress[publicKey as Address]?.usdc.formatted.toString() ?? '0';
  };
  const balance = getBalance();
  const handleAmountChange = (input: string) => {
    const inputValue = sanitizeInputs(input);
    if (asset === 'ETH' && BigNumber(inputValue).isGreaterThan(BigNumber(balance).minus(BigNumber(executionFee)))) {
      setErr('Insufficient balance');
    } else if (asset === 'USDC' && BigNumber(inputValue).isGreaterThan(BigNumber(balance))) {
      setErr('Insufficient balance');
    } else if (inputValue === '' || inputValue === '0') {
      setErr('Amount cannot be empty');
    } else {
      setErr(null);
    }
    setTradeEst(Math.floor(Number(inputValue) / Number(gasPerTradeEst?.ethPerTradeEst)));
    setTradeCount(Math.floor(Number(inputValue) / Number(gasPerTradeEst?.ethPerTradeEst)));
    setInput(inputValue);
  };
  const handleAction = async () => {
    if (isAdd && asset === 'ETH') {
      await depositETH({
        to: publicKey as Address,
        amount: BigInt(BigNumber(input).multipliedBy(1e18).toFixed(0))
      });
    }
    if (isAdd && asset === 'USDC') {
      await depositUSDC({
        to: publicKey as Address,
        amount: BigInt(BigNumber(input).multipliedBy(1e6).toFixed(0))
      });
    }
    if (!isAdd && asset === 'ETH') {
      await withdrawETH({
        to: address as Address,
        amount: BigInt(BigNumber(input).multipliedBy(1e18).toFixed(0))
      });
    }
    if (!isAdd && asset === 'USDC') {
      await withdrawUSDC({
        to: address as Address,
        amount: BigInt(BigNumber(input).multipliedBy(1e6).toFixed(0))
      });
    }
    onComplete();
  };
  const handleMaxClick = () => {
    if (asset === 'ETH' && BigNumber(balance).isGreaterThan(BigNumber(executionFee))) {
      const amountBn = BigNumber(balance).minus(BigNumber(executionFee));
      const amount = amountBn.toFixed(getPrecision(amountBn));
      handleAmountChange(amount);
    } else if (asset === 'ETH') handleAmountChange('0');else handleAmountChange(balance);
  };
  useEffect(() => {
    if (asset === 'ETH' && isAdd) {
      const defaultTradeCount = 15;
      handleAmountChange((defaultTradeCount * Number(gasPerTradeEst?.ethPerTradeEst)).toString());
      setTradeCount(defaultTradeCount);
    } else if (asset === 'ETH' && !isAdd) {
      handleAmountChange(BigNumber(balance).minus(BigNumber(executionFee)).toFixed());
    } else {
      handleAmountChange('10');
    }
  }, []);
  return <ResponsiveDialog open={openManageFunds} heading={isAdd ? `Deposit ${asset} into 1CT Wallet` : `Withdraw ${asset} to Connected Wallet`} footer={<Button className="w-full" variant={'long'} onClick={handleAction} disabled={!!err}>
          <ButtonCTA isAdd={isAdd} asset={asset} amount={input} />
        </Button>} onCleanup={() => {
    setOpenManageFunds(false);
    onCleanup();
  }} data-sentry-element="ResponsiveDialog" data-sentry-component="ManageFundsModal" data-sentry-source-file="ManageFundsModal.tsx">
      <div className="py-4 md:px-8 md:py-6">
        <BalanceSection asset={asset} balance={currentWalletBalance} data-sentry-element="BalanceSection" data-sentry-source-file="ManageFundsModal.tsx" data-sentry-element="BalanceSection" />
        <hr className="my-6 h-[1px] border-t-0 bg-grey-500 w-full" />
        <InputWithBalance handleMaxClick={() => handleMaxClick()} amount={input} handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAmountChange(e.target.value)} header={'Amount'} balance={balance} precision={6} error={!!err} rightSide={<InputRightSide asset={asset} isAdd={isAdd} tradeEst={tradeEst} />} data-sentry-element="InputWithBalance" data-sentry-source-file="ManageFundsModal.tsx" data-sentry-element="InputWithBalance" />
        {err && <p className="text-red-500 text-sm mt-2">{err}</p>}
        {asset === 'ETH' && isAdd && <TradesCount tradeCount={tradeCount} setTradeCount={setTradeCount} perTradeEst={Number(gasPerTradeEst?.ethPerTradeEst)} handleAmount={handleAmountChange} />}
      </div>
    </ResponsiveDialog>;
};
export default ManageFundsModal;