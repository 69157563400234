'use client';

import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from '@/components/ui/toast';
import { useToast } from '@/components/ui/use-toast';
import { cn } from '@/lib/utils';
import { ToastPosition, useUIStore } from '@/store/ui';
const toastClassesByPosition = {
  [ToastPosition.TOP_RIGHT]: 'top-0 right-0 md:top-[51px] md:right-0',
  [ToastPosition.TOP_LEFT]: 'top-0 left-0 md:top-[51px] md:left-0',
  [ToastPosition.BOTTOM_RIGHT]: 'top-0 right-0 md:bottom-7 md:right-0',
  [ToastPosition.BOTTOM_LEFT]: 'top-0 left-0 md:bottom-7 md:left-0'
};
export function Toaster() {
  const {
    toasts
  } = useToast();
  const {
    toastPosition
  } = useUIStore();
  const getPosition = (position: ToastPosition): 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left' => {
    switch (position) {
      case ToastPosition.TOP_RIGHT:
        return 'top-right';
      case ToastPosition.TOP_LEFT:
        return 'top-left';
      case ToastPosition.BOTTOM_RIGHT:
        return 'bottom-right';
      case ToastPosition.BOTTOM_LEFT:
        return 'bottom-left';
      default:
        return 'top-right';
    }
  };
  return <ToastProvider data-sentry-element="ToastProvider" data-sentry-component="Toaster" data-sentry-source-file="toaster.tsx">
      {toasts.map(function ({
      id,
      title,
      icon,
      description,
      action,
      ...props
    }) {
      return <Toast key={id} {...props} position={getPosition(toastPosition)} className={cn('p-4 bg-grey-1000 md:w-[440px] md:h-fit')}>
            <div className="flex items-center gap-4">
              {icon && <div>{icon}</div>}
              {(title || description) && <div>
                  {title && <ToastTitle className="text-xl font-medium text-grey-000">{title}</ToastTitle>}
                  {description && <ToastDescription className="text-base text-grey-100">
                      {description}
                    </ToastDescription>}
                  {action}
                </div>}
            </div>
            <ToastClose className="ml-4" />
          </Toast>;
    })}
      <ToastViewport className={toastClassesByPosition[toastPosition]} data-sentry-element="ToastViewport" data-sentry-source-file="toaster.tsx" data-sentry-element="ToastViewport" />
    </ToastProvider>;
}