'use client';

import ResponsivePopover from '../ResponsivePopover';
import { HiOutlineLightningBolt, HiLightningBolt } from 'react-icons/hi';
import useWalletBalances from '@/hooks/shared/useWalletBalance';
import { useAccount, useDisconnect } from 'wagmi';
import Image from 'next/image';
import { cn, formatAddress } from '@/lib/utils';
import { ChevronDown, Copy, Dot, SquareArrowOutUpRight } from 'lucide-react';
import { IoMdLogOut } from 'react-icons/io';
import Link from 'next/link';
import { blockchainExplorerUrl } from '@/blockchain/configs/wagmi';
import Spinner from '../Spinner';
import { useCopyToClipboard } from 'usehooks-ts';
import useENS from '@/hooks/shared/useENS';
import { useQuery } from '@tanstack/react-query';
import OneCTSection from './OneCTSection';
import { useOneCTStore } from '@/store/oneCT';
import useOneCT from '@/hooks/shared/useOneCT';
import { roundDownTrim } from '@/lib/numbers';
import { Button } from '@/components/ui/button';
import { useUserStore } from '@/store/user';
import { useRouter } from 'next/navigation';
import CoinbasePay from './CoinbasePay';
import useSmartWallet from '@/hooks/shared/useSmartWallet';
import { useToast } from '@/components/ui/use-toast';
const OneCTMenu = () => {
  const {
    isSmartWallet
  } = useSmartWallet();
  const {
    oneCTWallets
  } = useOneCT();
  const updateUserStore = useUserStore(store => store.updateUserStore);
  if (isSmartWallet) {
    return null;
  } else if (oneCTWallets && oneCTWallets.length > 0) {
    return oneCTWallets.map((wallet, key) => {
      return <OneCTSection wallet={wallet} key={key} />;
    });
  }
  return <div className="border border-primary-500 p-4 flex items-center gap-6 rounded-lg relative gradient-violet-1" data-sentry-component="OneCTMenu" data-sentry-source-file="WalletDetails.tsx">
      <div className="flex items-center gap-1">
        <HiLightningBolt className="text-primary size-6" data-sentry-element="HiLightningBolt" data-sentry-source-file="WalletDetails.tsx" data-sentry-element="HiLightningBolt" />
        <div className="flex flex-col">
          <p className="font-fono text-sm font-medium text-grey-025">One-Click Trading Available</p>
          <p className="text-grey-100 text-xs font-normal">Trading without signing transactions</p>
        </div>
      </div>
      <Button variant="default" className="px-2 py-1 w-[83px] h-6 font-fono font-medium text-xs z-50" onClick={() => {
      updateUserStore('openOnboard1CT', true);
    }} data-sentry-element="Button" data-sentry-source-file="WalletDetails.tsx" data-sentry-element="Button">
        Enable 1CT
      </Button>

      <Image src="/images/1ct/card-bg.svg" width={84} height={66} alt="card bg" className="absolute right-0" data-sentry-element="Image" data-sentry-source-file="WalletDetails.tsx" data-sentry-element="Image" />
    </div>;
};
const WalletDetails = () => {
  const {
    address,
    connector
  } = useAccount();
  const router = useRouter();
  const {
    currentWalletBalance,
    isLoading: isLoadingBalances,
    walletBalanceByAddress
  } = useWalletBalances();
  const [, copy] = useCopyToClipboard();
  const {
    disconnect
  } = useDisconnect();
  const {
    name,
    avatar
  } = useENS();
  const {
    toast
  } = useToast();
  const {
    publicKey
  } = useOneCTStore(state => state);
  const {
    data: icon
  } = useQuery({
    queryKey: ['connector-icon', connector?.name, address],
    queryFn: async () => {
      const icon = await (connector as any).rkDetails.iconUrl();
      return icon;
    },
    enabled: !!connector && !connector.icon && (connector as any)?.rkDetails?.iconUrl
  });
  if (!address || !connector) {
    return;
  }
  const usdcBalance = currentWalletBalance?.usdc.formatted ?? 0;
  const eoaUSDCBalance = walletBalanceByAddress[address]?.usdc.formatted ?? 0;
  const handleCopyAddress = () => {
    copy(address);
    toast({
      title: 'Address copied to clipboard'
    });
  };
  return <ResponsivePopover trigger={<>
          <div role="button" className={cn('hidden p-3 rounded bg-grey-600 h-11 md:flex gap-2 items-center', publicKey ? 'border-secondary-400 border' : '')}>
            {publicKey ? <HiLightningBolt className="text-primary size-6" /> : <HiOutlineLightningBolt className="text-primary size-6" />}
            <span className="font-semibold">
              {isLoadingBalances ? <Spinner className="size-5" /> : roundDownTrim(Number(usdcBalance))}
            </span>{' '}
            {!isLoadingBalances && <span className="hidden md:inline">USDC</span>}
            <ChevronDown />
          </div>
          <div className="md:hidden h-8 md:h-11 px-2 flex items-center md:p-3 rounded bg-grey-600 font-medium">
            <Image src="/images/icons/wallet.svg" alt="wallet" width={20} height={20} />
          </div>
        </>} contentClassnames="sm:min-w-[411px] sm:max-w-[411px] max-h-[70vh] overflow-y-auto md:max-h-full scrollbar-hide" data-sentry-element="ResponsivePopover" data-sentry-component="WalletDetails" data-sentry-source-file="WalletDetails.tsx">
      <div className="flex flex-col gap-3">
        <div className="flex gap-2 justify-between items-center">
          <div className="flex items-center gap-2">
            <div className="size-11 md:size-12 rounded-full bg-grey-500 flex items-center justify-center">
              <Image loader={({
              src
            }) => src} src={avatar ?? (connector.icon || icon || '/images/wallet.svg')} alt={connector.name} width={30} height={30} style={{
              borderRadius: '50%'
            }} className="md:hidden" data-sentry-element="Image" data-sentry-source-file="WalletDetails.tsx" data-sentry-element="Image" />

              <Image loader={({
              src
            }) => src} src={avatar ?? (connector.icon || icon || '/images/wallet.svg')} alt={connector.name} width={40} height={40} style={{
              borderRadius: '50%'
            }} className="hidden md:block" data-sentry-element="Image" data-sentry-source-file="WalletDetails.tsx" data-sentry-element="Image" />
            </div>
            <div>
              <p className="font-medium">{connector.name}</p>
              <div className="flex items-center">
                <div className="hidden md:block">
                  <span className="text-muted font-medium">Base</span>
                  <Dot className="text-muted inline size-4" data-sentry-element="Dot" data-sentry-source-file="WalletDetails.tsx" data-sentry-element="Dot" />
                </div>
                <span className="text-muted font-medium">{name ?? formatAddress(address)}</span>
                <Copy className="size-4 text-muted cursor-pointer ml-2" onClick={() => handleCopyAddress()} data-sentry-element="Copy" data-sentry-source-file="WalletDetails.tsx" data-sentry-element="Copy" />
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Link href={`${blockchainExplorerUrl}/address/${address}`} target="_blank" data-sentry-element="Link" data-sentry-source-file="WalletDetails.tsx" data-sentry-element="Link">
              <div className="size-10 p-[10px] md:size-11 flex items-center justify-center border border-grey-500 rounded">
                <SquareArrowOutUpRight className="size-5 md:size-5" data-sentry-element="SquareArrowOutUpRight" data-sentry-source-file="WalletDetails.tsx" data-sentry-element="SquareArrowOutUpRight" />
              </div>
            </Link>
            <div className="size-10 p-[10px] md:size-11 flex items-center justify-center border border-grey-500 rounded" role="button" onClick={() => disconnect?.()}>
              <IoMdLogOut className="size-5" data-sentry-element="IoMdLogOut" data-sentry-source-file="WalletDetails.tsx" data-sentry-element="IoMdLogOut" />
            </div>
          </div>
        </div>

        <OneCTMenu data-sentry-element="OneCTMenu" data-sentry-source-file="WalletDetails.tsx" data-sentry-element="OneCTMenu" />

        <div className="gradient-violet rounded-lg p-4 flex flex-col gap-2">
          <p className="text-grey-100 font-normal">Wallet Balance</p>
          <p className="text-grey-025 font-medium text-xl font-fono">{eoaUSDCBalance} USDC</p>
          <div className="flex gap-2">
            <Button variant="oneCT" className="w-full" onClick={() => {
            router.push('/bridge');
          }} data-sentry-element="Button" data-sentry-source-file="WalletDetails.tsx" data-sentry-element="Button">
              Bridge/Swap
            </Button>
            <CoinbasePay data-sentry-element="CoinbasePay" data-sentry-source-file="WalletDetails.tsx" data-sentry-element="CoinbasePay" />
          </div>
        </div>
      </div>
    </ResponsivePopover>;
};
export default WalletDetails;