import { Dispatch, SetStateAction } from 'react';
import Pin from './Pin';
const PinModalContent = ({
  pin,
  setPin,
  pinError
}: {
  pin: string;
  setPin: Dispatch<SetStateAction<string>>;
  pinError: boolean;
}) => {
  return <div className="py-6 px-11 flex flex-col items-center justify-center gap-6" data-sentry-component="PinModalContent" data-sentry-source-file="PinModalContent.tsx">
      <p className="text-grey-100 font-normal text-base">
        Please enter your 4-digit PIN to re-enable 1CT.
      </p>
      <Pin setPin={setPin} pin={pin} data-sentry-element="Pin" data-sentry-source-file="PinModalContent.tsx" data-sentry-element="Pin" />

      {pinError && <div className="flex flex-col gap-2 items-center">
          <p className="text-red-500 font-normal text-sm">Invalid PIN</p>
          <div className="inline-flex gap-1">
            <p className="">Create</p>
            <p>{`another 1CT wallet if you've forgetten your old pin`}</p>
          </div>
        </div>}
    </div>;
};
export default PinModalContent;