import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { useUserStore } from '@/store/user';
import { CheckCircleIcon } from 'lucide-react';
import Image from 'next/image';
import { useCallback, useMemo } from 'react';
import { useConfig } from 'wagmi';
const formatError = (error: string) => {
  const insufficientError = error.includes('exceeds the balance of the account');
  if (insufficientError) {
    return 'Insufficient gas';
  }
  return error;
};
const useAppToast = () => {
  const {
    toast
  } = useToast();
  const {
    chains
  } = useConfig();
  const {
    updateUserStore
  } = useUserStore();
  const getTxUrl = useCallback((hash: string) => `${chains[0].blockExplorers.default.url}/tx/${hash}`, [chains]);
  const toasts = useMemo(() => ({
    info: (info: string) => {
      toast({
        icon: <>
              <Image src="/images/toast/blocks-processing.svg" width={45} height={85} alt="toast" className="hidden md:block" />
              <Image src="/images/toast/blocks-processing.svg" width={30} height={60} alt="toast" className="md:hidden" />
            </>,
        title: info
      });
    },
    txnInitiate: () => {
      toast({
        icon: <>
              <Image src="/images/toast/blocks-processing.svg" width={45} height={85} alt="toast" className="hidden md:block" />
              <Image src="/images/toast/blocks-processing.svg" width={30} height={60} alt="toast" className="md:hidden" />
            </>,
        title: 'Please confirm in your wallet',
        description: 'Please wait for a few moments....'
      });
    },
    txnSuccess: (hash: string) => {
      toast({
        icon: <>
              <Image src="/images/toast/blocks-success.svg" width={43} height={87} alt="toast" className="hidden md:block" />
              <Image src="/images/toast/blocks-success.svg" width={30} height={60} alt="toast" className="md:hidden" />
            </>,
        title: 'Transaction successful.',
        action: <Button variant={'navLink'} className="text-base p-0 focus:border-none rounded-[999px] leading-[100%] text-primary-200" onClick={() => window.open(getTxUrl(hash), '_blank')}>
              View Transaction
            </Button>
      });
    },
    processing: () => {
      toast({
        icon: <>
              <Image src="/images/toast/blocks-processing.svg" width={45} height={85} alt="toast" className="hidden md:block" />
              <Image src="/images/toast/blocks-processing.svg" width={30} height={60} alt="toast" className="md:hidden" />
            </>,
        title: 'Almost there!',
        description: 'Waiting for transaction confirmation...'
      });
    },
    notWhitelisted: () => toast({
      icon: <>
              <Image src="/images/toast/blocks-fail.svg" width={77} height={52} alt="toast" className="hidden md:block" />
              <Image src="/images/toast/blocks-fail.svg" width={40} height={30} alt="toast" className="md:hidden" />
            </>,
      title: 'Your wallet is not whitelisted.',
      variant: 'destructive'
    }),
    failed: (error: string) => toast({
      icon: <>
              <Image src="/images/toast/blocks-fail.svg" width={77} height={52} alt="toast" className="hidden md:block" />
              <Image src="/images/toast/blocks-fail.svg" width={60} height={40} alt="toast" className="md:hidden" />
            </>,
      title: 'Something went wrong!',
      description: formatError(error)
    }),
    txnFailed: (error: string) => toast({
      icon: <>
              <Image src="/images/toast/blocks-fail.svg" width={77} height={52} alt="toast" className="hidden md:block" />
              <Image src="/images/toast/blocks-fail.svg" width={40} height={30} alt="toast" className="md:hidden" />
            </>,
      title: 'Transaction failed!',
      description: formatError(error)
    }),
    copied: (message: string) => toast({
      icon: <>
              <CheckCircleIcon width={77} height={77} color="green" className="hidden md:block" />
              <CheckCircleIcon width={40} height={40} color="green" className="md:hidden" />
            </>,
      title: 'Copied',
      description: message
    }),
    privateKeyCopied: () => toast({
      icon: <>
              <CheckCircleIcon width={77} height={77} color="green" className="hidden md:block" />
              <CheckCircleIcon width={40} height={40} color="green" className="md:hidden" />
            </>,
      title: 'Private Key Copied to Clipboard',
      description: 'Never share your private key with anyone!'
    }),
    oneCTNudge: () => toast({
      icon: <>
              <Image src="/images/1ct/toast-image.svg" width={64} height={64} alt="toast" className="hidden md:block" />
              <Image src="/images/1ct/toast-image.svg" width={64} height={64} alt="toast" className="md:hidden" />
            </>,
      title: 'One-Click Trading available',
      description: <div className="flex flex-col max-w-[300px]">
              <p className="text-wrap text-sm">{`We noticed you don’t have 1CT enabled`}</p>
              <p className="text-wrap text-sm">
                With 1CT, you can place a trade without signing transactions.
              </p>
            </div>,
      action: <p className="text-sm font-medium text-primary-200 underline underline-offset-2 underline-primary-200 cursor-pointer my-2" onClick={() => {
        updateUserStore('openOnboard1CT', true);
      }}>
              Enable 1CT
            </p>
    })
  }), [toast, getTxUrl]);
  return {
    ...toasts
  };
};
export default useAppToast;