import { requestApp } from '@/lib/api';
import { useQuery } from '@tanstack/react-query';

type TAppStatus = {
  isActive: boolean;
  message: string;
  backgroundColor: string;
  color: string;
  operationalColor: string;
  operationalMessage: string;
  allowAutoOverride: boolean;
  isGlobal: boolean;
  errorMessage?: string;
  success: boolean;
};

export default function useAppStatus() {
  return useQuery({
    queryKey: ['app-status'],
    queryFn: async () => {
      const response = await requestApp<TAppStatus>({ endpoint: '/app/status' });

      return response;
    },
    refetchInterval: 60_000,
  });
}
