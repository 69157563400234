'use client';

import Image from 'next/image';
import Marquee from 'react-fast-marquee';
import { useUIStore } from '@/store/ui';
import { announcements, AnnouncementType } from '@/constants/announcements';
import { X } from 'lucide-react';
export default function Announcement() {
  const showAnnouncements = useUIStore(state => state.showAnnouncements);
  const updateAnnouncements = useUIStore(state => state.updateAnnouncements);
  const closeAnnouncementBanner = () => {
    updateAnnouncements(false);
  };
  if (!showAnnouncements) {
    return;
  }
  return <>
      <div className="relative z-[21] flex items-center bg-[#181a20] w-full p-4 gap-5 border-b border-grey-500 box-border">
        {announcements.type === AnnouncementType.MARQUEE && <div className="flex items-center justify-between w-full gap-2 md:gap-6">
            <div className="flex items-center bg-[#bdf556] text-[#101115] p-1.5 md:px-4 md:py-2 rounded-full gap-2 min-w-fit">
              <Image src={`/images/stars-black.svg`} width={15} height={15} alt="new_assets" />
              <span className="font-primary text-xs md:text-sm font-medium leading-5 whitespace-nowrap">
                {announcements.label}
              </span>
            </div>

            <Marquee pauseOnClick pauseOnHover autoFill>
              {announcements.value.map(data => <div className="flex justify-center items-center mr-4 gap-4" key={data.id}>
                  <div className="flex items-center justify-start gap-2 text-[#d1d4dc]">
                    <div className="w-6 h-6 relative">
                      <Image src={data.assetUri} fill alt={data.base} />
                    </div>
                    <p className="text-grey-200 leading-8 tracking-wide">
                      Trade{' '}
                      <span className="text-grey-100 font-medium">
                        {data.base}-{data.quote}
                      </span>{' '}
                      with up to <span className="text-grey-100 font-medium">{data.leverage}x</span>{' '}
                      Leverage
                    </p>
                  </div>

                  <div className="w-2 h-2 rounded-full bg-grey-500"></div>
                </div>)}
            </Marquee>
          </div>}

        {announcements.type === AnnouncementType.STILL && <div className="flex items-center justify-between w-full gap-6">
            <div className="flex items-center bg-[#bdf556] text-[#101115] p-1.5 rounded-full gap-2">
              <Image src={`/images/stars-squared.svg`} width={15} height={15} alt="new_assets" />
              <span>AVANTIS XP IS LIVE</span>
            </div>

            <div className="w-full overflow-hidden">
              <span className="text-grey-050 font-primary text-base font-normal leading-8 tracking-wide">
                {announcements.value[0].desc}
              </span>
            </div>

            <div className="whitespace-nowrap cursor-pointer" onClick={() => {
          closeAnnouncementBanner();
        }}>
              <span className="text-[#96e21b] font-primary text-base font-medium leading-8 tracking-wide underline">
                Go to Avantis XP dashboard
              </span>
            </div>
          </div>}

        <div className="w-7 h-7 cursor-pointer" onClick={closeAnnouncementBanner}>
          <X className="size-7" data-sentry-element="X" data-sentry-source-file="Announcement.tsx" data-sentry-element="X" />
        </div>
      </div>
    </>;
}